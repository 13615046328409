var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"CLP Site Pages","title":"CLP Site Pages","clpSitePageSettings":_vm.clpSitePageSettings,"memberData":_vm.memberData}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Rewards',
          icon: 'fa fa-gift',
          path: ("/" + (this.clpSitePageSettings.siteName) + "/rewards")
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Update Profile',
          icon: 'fa fa-address-card',
          path: ("/" + (this.clpSitePageSettings.siteName) + "/profile")
        }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view',{attrs:{"clpSitePageSettings":_vm.clpSitePageSettings,"memberData":_vm.memberData,"sitePageTheme":_vm.sitePageTheme}})],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }