<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="CLP Site Pages"
      title="CLP Site Pages"
      :clpSitePageSettings="clpSitePageSettings"
      :memberData="memberData"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Rewards',
            icon: 'fa fa-gift',
            path: `/${this.clpSitePageSettings.siteName}/rewards`
          }"
        />

        <sidebar-item
          :link="{
            name: 'Update Profile',
            icon: 'fa fa-address-card',
            path: `/${this.clpSitePageSettings.siteName}/profile`
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view
            :clpSitePageSettings="clpSitePageSettings"
            :memberData="memberData"
            :sitePageTheme="sitePageTheme"
          ></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import secureStorage from '../controllers/secureStorage';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition
  },

  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary,
      clpSitePageSettings: null,
      memberData: null,
      sitePageTheme: null
    };
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    createSitePageTheme: function() {
      this.sitePageTheme = {
        header: {
          backgroundColor: this.clpSitePageSettings.header_bar_color,
          fontFamily: this.clpSitePageSettings.title_font
        },
        body: {
          backgroundColor: this.clpSitePageSettings.background_color,
          fontFamily: this.clpSitePageSettings.body_font
        }
      };
    }
  },

  created() {
    this.clpSitePageSettings = secureStorage.getItem('clpSitePageSettings');
    this.memberData = secureStorage.getItem('clpMemberData');
    this.createSitePageTheme();
  }
};
</script>
<style lang="scss"></style>
