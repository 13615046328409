import axios from 'axios';
import secureStorage from '../controllers/secureStorage.js';

const HTTPRequest = {
  async getRequest(endPoint, parameters) {
    let responseData;
    await axios({
      method: 'GET',
      url: endPoint,
      headers: {
        Authorization:
          'Bearer ' + secureStorage.getItem('clpSitePageAccessToken'),
        'Access-Controll-Allow-Origin': '*'
      },
      params: parameters
    })
      .then(function(response) {
        responseData = response.data;
      })
      .catch(function(error) {
        let errors = [];
        if (error.response.data.message != undefined) {
          if (error.response.data.message == 'Validation error') {
            errors.push(error.response.data.errors[0].msg);
          } else {
            errors.push(error.response.data.message);
          }
        } else {
          if (error.response.statusText != undefined)
            errors.push(error.response.statusText);
          if (error.error != undefined) errors.push(error.error);
        }
        responseData = { error: errors.join(',') };
      });

    return responseData;
  },

  async postRequest(endPoint, requestBody) {
    let responseData;
    await axios({
      method: 'POST',
      url: endPoint,
      headers: {
        Authorization:
          'Bearer ' + secureStorage.getItem('clpSitePageAccessToken'),
        'Access-Controll-Allow-Origin': '*'
      },
      data: requestBody
    })
      .then(function(response) {
        console.log('postRequest: ', response);
        responseData = response.data;
      })
      .catch(function(error) {
        console.log('catch: ', error);

        let errors = [];
        if (error.response.data.message != undefined) {
          if (error.response.data.message == 'Validation error') {
            errors.push(error.response.data.errors[0].msg);
          } else {
            errors.push(error.response.data.message);
          }
        } else {
          if (error.response.statusText != undefined)
            errors.push(error.response.statusText);
          if (error.error != undefined) errors.push(error.error);
        }
        responseData = { error: errors.join(',') };
      });

    return responseData;
  },

  async putRequest(endPoint, requestBody) {
    let responseData;
    await axios({
      method: 'PUT',
      url: endPoint,
      headers: {
        Authorization:
          'Bearer ' + secureStorage.getItem('clpSitePageAccessToken'),
        'Access-Controll-Allow-Origin': '*'
      },
      data: requestBody
    })
      .then(function(response) {
        responseData = response.data;
      })
      .catch(function(error) {
        let errors = [];
        if (error.response.data.message != undefined) {
          if (error.response.data.message == 'Validation error') {
            errors.push(error.response.data.errors[0].msg);
          } else {
            errors.push(error.response.data.message);
          }
        } else {
          if (error.response.statusText != undefined)
            errors.push(error.response.statusText);
          if (error.error != undefined) errors.push(error.error);
        }
        responseData = { error: errors.join(',') };
      });

    return responseData;
  }
};

export default HTTPRequest;
