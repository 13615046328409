import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import Statistic from 'ant-design-vue/lib/statistic'; // for js
import Tree from 'ant-design-vue/lib/tree'; // for js
import Input from 'ant-design-vue/lib/input'; // for js
import Drawer from 'ant-design-vue/lib/drawer'; // for js
import CheckBox from 'ant-design-vue/lib/checkbox'; // for js
import Switch from 'ant-design-vue/lib/switch'; // for js
import Tabs from 'ant-design-vue/lib/tabs'; // for js
import Select from 'ant-design-vue/lib/select'; // for js
import Popconfirm from 'ant-design-vue/lib/popconfirm'; // for js
import DatePicker from 'ant-design-vue/lib/date-picker'; // for js
import TimePicker from 'ant-design-vue/lib/time-picker'; // for js
import Steps from 'ant-design-vue/lib/steps';
import Upload from 'ant-design-vue/lib/upload';
import Modal from 'ant-design-vue/lib/modal';
import Collapse from 'ant-design-vue/lib/collapse';
import Radio from 'ant-design-vue/lib/radio';
import List from 'ant-design-vue/lib/list';
import Dropdown from 'ant-design-vue/lib/dropdown';
import Menu from 'ant-design-vue/lib/menu';
import Slider from 'ant-design-vue/lib/slider';
import Spin from 'ant-design-vue/lib/spin';
import Cascader from 'ant-design-vue/lib/cascader';
import Table from 'ant-design-vue/lib/table';
import InputNumber from 'ant-design-vue/lib/input-number';
import Tag from 'ant-design-vue/lib/tag';
import notification from 'ant-design-vue/lib/notification';
import message from 'ant-design-vue/lib/message';
import Card from 'ant-design-vue/lib/card';
import AutoComplete from 'ant-design-vue/lib/auto-complete';
import Button from 'ant-design-vue/lib/button';
import Icon from 'ant-design-vue/lib/icon';
import Empty from 'ant-design-vue/lib/empty';
import Pagination from 'ant-design-vue/lib/pagination';
import Rate from 'ant-design-vue/lib/rate';
import Avatar from 'ant-design-vue/lib/avatar';
import Popover from 'ant-design-vue/lib/popover';
import Badge from 'ant-design-vue/lib/badge';
import Tooltip from 'ant-design-vue/lib/tooltip';
import Form from 'ant-design-vue/lib/form';

import 'ant-design-vue/lib/tree/style/css'; // for css
import 'ant-design-vue/lib/input/style/css'; // for css
import 'ant-design-vue/lib/drawer/style/css'; // for css
import 'ant-design-vue/lib/checkbox/style/css'; // for css
import 'ant-design-vue/lib/switch/style/css'; // for css
import 'ant-design-vue/lib/tabs/style/css'; // for css
import 'ant-design-vue/lib/select/style/css'; // for css
import 'ant-design-vue/lib/popconfirm/style/css'; // for css
import 'ant-design-vue/lib/date-picker/style/css'; // for css
import 'ant-design-vue/lib/time-picker/style/css'; // for css
import 'ant-design-vue/lib/steps/style/css'; // for css
import 'ant-design-vue/lib/upload/style/css';
import 'ant-design-vue/lib/modal/style/css';
import 'ant-design-vue/lib/collapse/style/css';
import 'ant-design-vue/lib/radio/style/css';
import 'ant-design-vue/lib/list/style/css';
import 'ant-design-vue/lib/dropdown/style/css';
import 'ant-design-vue/lib/menu/style/css';
import 'ant-design-vue/lib/slider/style/css';
import 'ant-design-vue/lib/spin/style/css';
import 'ant-design-vue/lib/cascader/style/css';
import 'ant-design-vue/lib/table/style/css';
import 'ant-design-vue/lib/input-number/style/css';
import 'ant-design-vue/lib/tag/style/css';
import 'ant-design-vue/lib/notification/style/css';
import 'ant-design-vue/lib/message/style/css';
import 'ant-design-vue/lib/card/style/css';
import 'ant-design-vue/lib/auto-complete/style/css';
import 'ant-design-vue/lib/button/style/css';
import 'ant-design-vue/lib/icon/style/css';
import 'ant-design-vue/lib/empty/style/css';
import 'ant-design-vue/lib/pagination/style/css';
import 'ant-design-vue/lib/rate/style/css';
import 'ant-design-vue/lib/avatar/style/css';
import 'ant-design-vue/lib/popover/style/css';
import 'ant-design-vue/lib/statistic/style/css';
import 'ant-design-vue/lib/badge/style/css';
import 'ant-design-vue/lib/tooltip/style/css';
import 'ant-design-vue/lib/form/style/css';

Vue.use(Tree);
Vue.use(Input);
Vue.use(Drawer);
Vue.use(CheckBox);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(Select);
Vue.use(Popconfirm);
Vue.use(TimePicker);
Vue.use(DatePicker);
Vue.use(Steps);
Vue.use(Upload);
Vue.use(Modal);
Vue.use(Collapse);
Vue.use(Radio);
Vue.use(List);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Slider);
Vue.use(Spin);
Vue.use(Cascader);
Vue.use(Table);
Vue.use(InputNumber);
Vue.use(Tag);
Vue.use(Card);
Vue.use(AutoComplete);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(Pagination);
Vue.use(Rate);
Vue.use(Avatar);
Vue.use(Popover);
Vue.use(Statistic);
Vue.use(Badge);
Vue.use(Tooltip);
Vue.use(Form);

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$destroyAll = Modal.destroyAll;

// Theme
import ArgonDashboard from './plugins/argon-dashboard';
Vue.config.productionTip = false;
Vue.use(ArgonDashboard);

// VueTable
// import VueTable from 'vuetable-2';
// Vue.use(VueTable);

// Vuelidate
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// VueTabulator
// import VueTabulator from 'vue-tabulator';
// Vue.use(VueTabulator);

import vuePdf from 'vue-pdf';
Vue.component('vue-pdf', vuePdf);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
